<template>
	<div class="layout container">
		<Header :user-access="userAccess" />

		<main class="container">
			<slot />
		</main>

		<Cookies />

		<Dialog
			v-model:visible="sessionInfoShow"
			modal>
			<template #container>
				<Card>
					<template #content>
						<div class="flex flex-column gap-4">
							<h3>{{ $t("global.max-sessions.global.text") }}</h3>

							<div class="flex flex-column gap-1">
								<p
									v-for="session in sessionStore.getSessions"
									:key="session.id">
									<span>{{ $t("global.max-sessions.global.start-date") }}: {{ session.start }}</span>
								</p>
							</div>

							<div class="flex justify-content-center gap-4">
								<Button
									:label="$t('global.max-sessions.global.button-yes')"
									@click="closeSessions" />
							</div>
						</div>
					</template>
				</Card>
			</template>
		</Dialog>

		<Dialog
			v-model:visible="getTabBlock"
			modal>
			<template #container>
				<Card>
					<template #content>
						<div class="flex flex-column gap-4">
							<h3>{{ $t("global.max-sessions.tabs.text") }}</h3>

							<div class="flex justify-content-center gap-4">
								<Button
									:label="$t('global.max-sessions.tabs.button-update')"
									@click="reload" />
							</div>
						</div>
					</template>
				</Card>
			</template>
		</Dialog>
	</div>
</template>

<script setup lang="ts">
	import Header from "~/components/layouts/default/header/Header.vue";
	import { useSession } from "~/store/session/session";
	import { $useFetch } from "~/composables/useFetch";
	import { api } from "~/api/api";

	const { status } = useAuth();

	const nuxtApp = useNuxtApp();

	const userAccess = ref(nuxtApp.userRoles.getUserAccess());

	const sessionStore = useSession();
	const { getTabBlock, getMaxSessions, getSessions } = storeToRefs(sessionStore);

	const sessionInfoShow = ref(false);

	function reload() {
		reloadNuxtApp({
			force: true,
		});
	}

	const { refresh: dropSessions } = $useFetch(api.deleteSession, {
		method: "DELETE",
		immediate: false,
		watch: false,
	});

	function closeSessions() {
		dropSessions();

		sessionInfoShow.value = false;
	}

	watch(
		() => status.value,
		(status) => {
			if (status === "unauthenticated") useRouter().push("/login");
		},
	);

	watchEffect(() => {
		sessionInfoShow.value = getSessions.value.length > getMaxSessions.value;
	});
</script>

<style scoped>
	@import "@/assets/scss/layouts/default/_styles.scss";
</style>
